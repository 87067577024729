<template>
  <h2>
    Porady na temat kredytu hipotecznego
  </h2>
  <ArticleTeasers :articles="articles"/>
</template>
<script setup lang="ts">
import type {Article} from "~/types/Article";

const articles: Article[]= [
  {
    img: '/imgs/kredyty-hipoteczne/umowa-kredytowa.webp',
    link: 'https://www.lendi.pl/blog/umowa-kredytowa-zapisy-trzeba-uwazac/',
    author: 'Paulina Gajewicz',
    title: `Umowa kredytowa – 7 zapisów, na które trzeba uważać`,
    content: 'Złożenie wniosku kredytowego i pozytywna decyzja banku otwierają drogę do uzyskania zobowiązania na zakup mieszkania. Dla wielu kredytobiorców umowa kredytowa to pierwszy tak ważny dokument do podpisania. Jego treść nie zawsze jest zrozumiała, dlatego podpowiadamy, na jakie zapisy zwrócić szczególną uwagę i jakie obowiązki wobec kredytobiorcy wiążą się z ich podpisaniem.',
  },
  {
    img: '/imgs/kredyty-hipoteczne/kredyt-hipoteczny-czy-gotowkowy.webp',
    link: 'https://www.lendi.pl/blog/kredyt-hipoteczny-czy-gotowkowy/',
    author: 'Wioletta Szczudlińska',
    title: 'Kredyt hipoteczny, czy gotówkowy? Jaki kredyt na mieszkanie? Kredyt hipoteczny a inne kredyty.',
    content: 'Większość nabywców mieszkań finansuje zakup kredytem albo pożyczką. Możliwości jest kilka, tylko, która z nich jest lepsza? Wybrać kredyt hipoteczny czy gotówkowy?',
  },
  {
    img: '/imgs/kredyty-hipoteczne/marza-kalkulator.webp',
    link: 'https://www.lendi.pl/blog/marza-kredytu-hipotecznego/',
    author: 'Wioletta Szczudlińska',
    title: 'Marża kredytu hipotecznego – co to jest marża i jak ją obliczyć?',
    content: 'Potencjalny kredytobiorca, zanim wypełni i złoży wniosek, powinien dobrze zaznajomić się ze wszystkimi kosztami zobowiązania. Jednym z nich jest marża kredytu hipotecznego. Nie każdy ma świadomość, że jej wysokość można z bankiem negocjować. Dlatego wyjaśniamy, co to jest marża, od czego zależy i jak ją obliczyć.',
  },
  {
    img: '/imgs/kredyty-hipoteczne/kompendium-wiedzy.webp',
    link: 'https://www.lendi.pl/blog/kredyt-hipoteczny-warunki/',
    author: 'Kornelia Wilczak',
    title: 'Kredyt hipoteczny: wymagania i warunki uzyskania kredytu',
    content: 'Kredyt hipoteczny to produkt finansowy umożliwiający zakup nieruchomości ze wsparciem banku. Decydując się na takie zobowiązanie, należy spełnić warunki kredytu hipotecznego. W zależności od banku, mogą się nieco różnić. Naturalnym jest więc, że w głowach wnioskujących pojawia się jedno, zasadnicze pytanie – czy dostanę kredyt hipoteczny? Jakie wymagania stawiają banki?',
  },
  {
    img: '/imgs/kredyty-hipoteczne/ranking-kredytow.webp',
    link: 'https://www.lendi.pl/blog/kredyt-hipoteczny-ze-stalym-oprocentowaniem/',
    author: 'Kornelia Wilczak',
    title: 'Kredyt hipoteczny ze stałym oprocentowaniem – czy warto decydować się na stałą stopę procentową?',
    content: 'Kredyt hipoteczny ze stałym oprocentowaniem jest jedną z bardziej popularnych form finansowania domów i mieszkań. Warto jednak wiedzieć, w jaki sposób wartość stopy procentowej wpływa na warunki kredytu. Czy kredyt hipoteczny ze stałą stopą procentową jest bardziej, czy mniej opłacalny niż tradycyjny kredyt? Jak stałe oprocentowanie kredytu hipotecznego przekłada się na spłatę powziętego zobowiązania?',
  },
  {
    img: 'https://www.lendi.pl/blog/wp-content/uploads/2022/07/Lendi-wpis-hipoteki-do-ksiegi-wieczystej.png',
    link: 'https://www.lendi.pl/blog/wpis-hipoteki-do-ksiegi-wieczystej/',
    author: 'Wioletta Szczudlińska',
    title: 'Wpis hipoteki do księgi wieczystej – koszt i czas oczekiwania',
    content: 'Wpis hipoteki do księgi wieczystej nieruchomości jest warunkiem uruchomienia kredytu hipotecznego. Dokonuje go sąd na wniosek właściciela nieruchomości. Ile trzeba zapłacić za dokonanie wpisu? Jak długo się czeka na rozpatrzenie wniosku?'
  },
  {
    img: 'https://www.lendi.pl/blog/wp-content/uploads/2021/11/RRSO-680x450.jpg',
    link: 'https://www.lendi.pl/blog/co-to-jest-rrso/',
    author: 'Wioletta Szczudlińska',
    title: 'Co to jest RRSO? Jakie RRSO jest korzystne?',
    content: 'Przy porównywaniu ofert kredytów i pożyczek zwykle zwracamy uwagę na oprocentowanie i marżę. Nie każdy wie, że dopiero RRSO powie, jakie są faktyczne koszty kredytu. Co to jest RRSO? Jakie elementy zawiera?'
  }
];
</script>
<style scoped lang="scss">

</style>